.input-group.code-input {
	label {
		font-size: 1.2rem;
		color: #17B6BC;
		width: 100%;
		margin: 0;
		text-align: center;

		input.form-control {
			margin-top: 1rem;
      font-size: 2rem;
			text-align: center;
			padding-right: 0;
			letter-spacing: 1.5rem;
			color: #34a3c1;
			border: 2px solid #79d5f5;
			border-radius: 8px;

      &::placeholder {
        color: #34a2c177;
      }
		}
	}
}
