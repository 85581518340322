.client-header-container {
	.header {
		padding: 1rem 2rem;
		display: flex;
		justify-content: space-between;

		.left {
			display: flex;
			align-items: center;

			.menu-icon {
				margin-right: 1rem;
				cursor: pointer;
				font-size: 1.4rem;
				color: #17b6bc;
			}

			.page-name {
				font-size: 1.4rem;
				color: #17b6bc;
			}
		}

		.right {
			display: flex;
			align-items: center;
			justify-content: space-between;

			span.name-text {
				font-size: 1.2rem;
				color: #17b6bc;
				margin: 0 1rem;
				font-weight: 500;
				cursor: pointer;
				user-select: none;
			}

			.header-dropdown-menu {
				background: rgba(23, 182, 188, 0.9);
				border-radius: 0;
				border: none;
				padding: 0 0.5rem;

				.dropdown-item {
					background: transparent;
					padding: 0.5rem;
					color: #fff;
					font-weight: 500;
					font-size: 1rem;
					cursor: pointer;

					&:hover {
						text-decoration: underline;
					}
				}
			}

			svg {
				width: 2rem;
				cursor: pointer;
			}
		}
	}

	.footer {
		background: #17b6bc;
		display: flex;
		justify-content: space-evenly;

		a {
			color: #fff;
			font-weight: 500;
			display: flex;
			align-items: center;
			font-size: 1rem;
			text-decoration: none;
			padding: 0.5rem 1rem;

			svg {
				margin-right: 0.5rem;
				width: 2rem;
			}

			&:hover {
				background: #139da1;
			}

			&.active {
				background: #139da1;
			}

			&.disabled {
				pointer-events: none;
				opacity: 0.5;
			}
		}
	}
}
