.form-control {
	display: block;
	width: 100%;
	padding: 0.4rem 0.75rem;
	font-size: 1.2rem;
	font-weight: 400;
	line-height: 1.5;
	color: #6db8d2;
	background-color: #fff;
	background-clip: padding-box;
	border: 2px solid #79d5f5;
	border-radius: 0.5rem;
	
	&.password {
		border-right: none;
	}

	&:focus {
		border-color: #79d5f5;
		color: #6ab3cc;
		box-shadow: 0 0 0 0.2rem rgba(121, 213, 245, 0.25);
	}

	&::placeholder {
		color: #65acc4;
	}
}

.password-icon {
	border: 2px solid #79d5f5;
	border-radius: 0.5rem;
	border-left: none;
	background: #fff;

	&:hover {
		background: none;
		border: 2px solid #79d5f5;
		border-left: none;
	}

	&:focus, &:active, &:focus:active {
		outline: none;
		box-shadow: none;
	}

	svg {
		font-size: 1.5rem;
		color: #79D5F5;
	}
}
