.user-profile-container {
	.card {
		border-radius: 1rem;
		border: 2px solid #bce9fd;

		.header {
			background: #17b6bc;
			color: #fff;
			padding: 1rem;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			border-radius: 1rem;

			* {
				margin: 0;
			}
		}
	}

	.save-container {
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 2rem 0;

		button.btn-blue {
			background: #17b6bc;
			color: #fff;
			border-radius: 0.5rem;
			padding: 0.5rem 2rem;
			width: 50%;

			&:hover {
				background: #19c4ca;
			}
		}
	}

	button.btn-red {
		background: #e87173;
		color: #fff;
		border-radius: 0.5rem;
		padding: 0.5rem 2rem;
		width: 60%;
		margin: auto;

		&:hover {
			background: #cf6567;
		}
	}
}
