div.register-content {
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  p.welcome-text {
    text-align: center;
    color: #17B6BC;
    font-size: 1.3rem;
  }

  p.form-title {
    color: #17B6BC;
    font-size: 1.3rem;
    font-weight: 500;
    width: 80%;
  }

  form {
    width: 80%;

    .form-group.button {
      display: flex;
      justify-content: center;

      .btn-red {
        background: #e87173;
        color: #fff;
        border-radius: 0.5rem;
        padding: 0.5rem 3rem;

        &:hover {
          background: #cf6567;
        }
      }
    }

    .form-check {
      display: inline-block;
      margin: 0.5rem;
      color: #17B6BC;
      font-size: 1rem;

      input[type="checkbox"] {
        border: 1px solid #17B6BC;

        &:checked {
          background: #17B6BC;
          background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
        }
      }
    }
  }
}

div.finish-register-content {
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  h2.title {
    font-size: 1.7rem;
    font-weight: 700;
    color: #17B6BC;
    margin-bottom: 1rem;
    text-align: center;
  }

  p.info {
    color: #17B6BC;
    font-size: 1rem;
    text-align: center;
  }

  p.warn {
    color: #17B6BC;
    font-size: 1rem;
    text-align: center;
    font-weight: 500;
  }
}
