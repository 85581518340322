.services-plans {
	p.main-p {
		margin-top: 1rem;
		margin-bottom: 0;
		color: #17b6bc;
		text-align: center;
		font-size: 1.4rem;
		font-weight: 500;
	}
	p.info-p {
		margin: 0 0 0 1rem;
		color: #17b6bc;
		text-align: center;
		font-size: 1.1rem;
		font-weight: 500;
	}

	.card-header {
		background-color: #17b6bc;
		color: #fff;
		font-size: 1.1rem;
		font-weight: 500;
		text-align: center;
		padding: 0.5rem;

		h3 {
			margin: 0;
			font-weight: 700;
		}
	}

	.card-body {
		* {
			color: #17b6bc;
		}
		.descuento {
			text-decoration: line-through;
			font-size: 1.1rem;
		}

		.congreso {
			font-size: 5 px;
		}
		.congreso1 {
			font-size: 0.9rem;
			text-align: center;
		}
		.image-container {
			display: flex;
			align-items: center;

			img.plan-image {
				width: 100%;
				height: auto;
			}
		}

		.text-container {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			padding: 0.5rem;

			p.includes {
				font-size: 1.1rem;
				font-weight: 500;
				margin: 0;
			}

			svg {
				width: 1.5rem;
				height: 1.5rem;
				margin-right: 0.5rem;
			}

			span.qty {
				font-size: 1rem;
			}
		}

		.btn-red {
			background: #e87173;
			color: #fff;
			border-radius: 0.5rem;
			padding: 0.2rem 1rem;
			font-weight: 500;
			margin: 1.5rem 0 0 0;

			&:hover {
				background: #cf6567;
			}
		}
	}
}
