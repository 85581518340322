.login-left {
  background-image: url("../../assets/images/login_image.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.login-right {
  padding: 0;

  .content {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .welcome-text {
      color: #17B6BC;
      font-size: 1.8rem;
      text-align: center;
    }

    .form-title {
      font-size: 2.2rem;
      font-weight: bold;
      color: #17B6BC;
      margin-bottom: 1rem;
    }

    .form-group {
      display: flex;
      justify-content: center;
      margin-bottom: 1rem;

      .input-group {
        width: 50%;
      }
    }

    .btn-red {
      background: #e87173;
      color: #fff;
      border-radius: 0.5rem;
      padding: 0.5rem 3rem;

      &:hover {
        background: #cf6567;
      }
    }

    .forgot-password {
      font-size: 1.1rem;
      color: #17B6BC;
      text-decoration: none;
      margin: 0.8rem 0;
      font-weight: 500;

      &:hover {
        text-decoration: underline;
      }
    }

    .register-link {
      font-size: 1.1rem;
      color: #e87173;
      text-decoration: none;
      margin: 0.8rem 0;
      font-weight: 500;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
