.input-label {
	display: flex;
	flex-direction: column;
	color: #17b6bc;
	font-weight: 500;
	font-size: 1rem;
	margin-top: 1rem;

	input {
		border: 2px solid #17b6bc;
		padding: 0.5rem;
		font-size: 1.1rem;
		color: #17b6bc;
		font-weight: 500;
		width: 100%;
		border-radius: 0.5rem;

		&:focus {
			outline: none;
			border: 2px solid #1cd2d8;
		}
	}

	span {
		color: #17b6bc;
		font-weight: 500;
		margin: 0;
	}
}

.countries-label {
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 1rem;
	margin-top: 1rem;

	span {
		color: #17b6bc;
		font-weight: 500;
		margin: 0;
	}

	select {
		width: 70%;
		border: 2px solid #17b6bc;
		padding: 0.5rem;
		font-size: 1.1rem;
		color: #17b6bc;
		font-weight: 500;
		border-radius: 0.5rem;

		&:focus {
			outline: none;
			border: 2px solid #1cd2d8;
		}
	}
}

.states-label {
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 1rem;
	margin-top: 1rem;

	span {
		color: #17b6bc;
		font-weight: 500;
		margin: 0;
	}

	select {
		width: 55%;
		border: 2px solid #17b6bc;
		padding: 0.5rem;
		font-size: 1.1rem;
		color: #17b6bc;
		font-weight: 500;
		border-radius: 0.5rem;

		&:focus {
			outline: none;
			border: 2px solid #1cd2d8;
		}
	}
}

.inline-input-label {
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 1rem;
	margin-top: 1rem;

	span {
		color: #17b6bc;
		font-weight: 500;
		margin: 0;
	}

	input {
		width: 70%;
		border: 2px solid #17b6bc;
		padding: 0.5rem;
		font-size: 1.1rem;
		color: #17b6bc;
		font-weight: 500;
		border-radius: 0.5rem;

		&:focus {
			outline: none;
			border: 2px solid #1cd2d8;
		}
	}
}
