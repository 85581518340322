.home-container {
  height: 100%;

  .left {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    h1 {
      font-weight: bold;
      color: #17b6bc;
    }
  }

  .right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    background-image: url("../../assets/images/imagen_home.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    .rounded-text {
      background: rgba(228, 115, 113, 0.77);
      max-width: 60%;
      padding: 1.5rem;
      border-top-left-radius: 2rem;
      border-bottom-left-radius: 2rem;

      p {
        color: #fff;
        font-weight: bold;
        font-size: 1.4rem;
        margin: 0;
        line-height: 1.5rem;
        text-align: end;
      }
    }
  }
}
