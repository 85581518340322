.login-footer {
	background: #17B6BC;
  width: 100%;
  display: flex;
  padding: 1rem 0;
  position: absolute;
  bottom: 0;
  justify-content: space-evenly;

  .footer-item {
    color: #fff;
    font-size: 1.1rem;
    font-weight: 600;
    text-transform: uppercase;
  }
}
